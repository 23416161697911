import Api from "./baseApi";
import bonusType from "@/lib/bonusType";

const state = {
  all: [],
  detail: {},
  cash: 0,
  redirectServer: {},
  ipAndType: [],
  ipGroup: [],
  deviceData: [],
  sigData: [],
  ipAdressAndTypeGroupData: [],
  userIdGroup: [],
  userIdDetail: [],
  botV2: []
};
const getters = {
  setting: (state) => {
    return state.setting;
  },
};
const actions = {
  getIpAdressAndTypeGroupData: async (context, type) => {
    let url = `/management/BotDetection/${type}`;
    let d = (await Api.get(url)).data;
    context.commit("getIpAdressAndTypeGroupData", d);
  },
  getBotV2: async (context, params) => {
    let url = `/management/BotDetection/list/?p=1`;
    if (params.level)
      url += `&level=${params.level}`;
    // if (params.status > -999)
    url += `&userStatus=${params.status}`;
    let query = {
      "size": 0,
      "query": {
        "bool": {
          "must": [
            {
              "range": {
                "createdTime": {
                  "gte": params.startDate
                }
              }
            },
            {
              "range": {
                "createdTime": {
                  "lte": params.endDate
                }
              }
            }
          ]
        }
      },
      "aggs": {
        "group_by_user": {
          "terms": {
            "field": "userId.keyword",
            "size": 5000
          },
          "aggs": {
            "bonusType": {
              "terms": {
                "field": "type",
                "size": 5000
              }
            },
            "totalChip": {
              "sum": {
                "field": "chip"
              }
            }
          }
        }
      }
    };
    if (params.bonusType > -1) {
      query.query.bool.must.push({
        match: {
          type: params.bonusType
        }
      })
    }
    let d = (await Api.post(url, query)).data;
    context.commit("getBotV2", d);
  },
  getdeviceData: async (context, type) => {
    let url = `/management/BotDetection/${type}`;
    let d = (await Api.get(url)).data;
    context.commit("getdeviceData", d);
  },
  getSigData: async (context, type) => {
    let url = `/management/BotDetection/${type}`;
    let d = (await Api.get(url)).data;
    context.commit("getSigData", d);
  },
  getByIpGroup: async (context, params) => {
    let query = {
      size: 0,
      query: {
        bool: {
          must: [
            {
              range: {
                createdTime: {
                  gte: params.startDate,
                },
              },
            },
          ],
        },
      },
      aggs: {
        group_by_user: {
          terms: {
            field: "ipAdress.keyword",
            "size": 100
          },
        },
      },
    };
    let d = (
      await Api.post(
        `/elastic/search/log_bonuses*`,
        query
      )
    ).data;
    context.commit("getByIpGroup", d);

  },
  getByIpAndTypeGroup: async (context, params) => {
    let query = {
      "size": 0,
      "query": {
        "bool": {
          "must": [
            {
              "range": {
                "createdTime": {
                  "gte": params.startDate
                }
              }
            }
          ]
        }
      },
      "aggs": {
        "group_by_user": {
          "terms": {
            "size": 10000,
            "field": "type",
            "missing": 0
          },
          "aggs": {
            "total": {
              "terms": {
                "field": "ipAdress.keyword",
                "size": 100
              }
            }
          }
        }
      }
    };
    let d = (
      await Api.post(
        `/elastic/search/log_bonuses*`,
        query
      )
    ).data;
    context.commit("getByIpAndTypeGroup", d);
  },
  getByUserIdGroup: async (context, params) => {
    let query = {
      size: 0,
      query: {
        bool: {
          must: [
            {
              range: {
                createdTime: {
                  gte: params.startDate,
                },
              },
            },
          ],
        },
      },
      aggs: {
        group_by_user: {
          terms: {
            field: "userId.keyword",
            "size": 100
          },
        },
      },
    };
    let d = (
      await Api.post(
        `/elastic/search/log_bonuses*`,
        query
      )
    ).data;
    context.commit("getByUserIdGroup", d);

  },
  getByUserIdBonus: async (context, params) => {
    let query = {
      "size": 0,
      "query": {
        "bool": {
          "must": [
            {
              "range": {
                "createdTime": {
                  "gte": params.startDate
                }
              }
            },
            {
              "match": {
                "userId.keyword": params.userId
              }
            }
          ]
        }
      },
      "aggs": {
        "group_by_user": {
          "terms": {
            "size": 10000,
            "field": "type",
            "missing": 0
          }
        }
      }
    };
    let d = (
      await Api.post(
        `/elastic/search/log_bonuses*`,
        query
      )
    ).data;
    context.commit("getByUserIdBonus", d);
  },
};
const mutations = {
  getIpAdressAndTypeGroupData(state, data) {
    state.ipAdressAndTypeGroupData = data;
  },
  getdeviceData(state, data) {
    state.deviceData = data;
  },
  getSigData(state, data) {
    state.sigData = data;
  },


  getRedirect(state, settings) {
    state.redirectServer = settings;
  },
  getByType(state, settings) {
    return settings;
  },
  get(state, id) {
    state.detail = state.all.find((m) => m.id == id);
  },
  delete(state, id) {
    state.all = state.all.filter((m) => m.id != id);
  },
  getByIpGroup(state, player) {
    state.ipGroup = player.aggregations.group_by_user.buckets;

  },
  getByUserIdGroup(state, player) {
    state.userIdGroup = player.aggregations.group_by_user.buckets;

  },
  getBotV2(state, player) {
    state.botV2 = player;

  },


  getByUserIdBonus(state, player) {
    state.userIdDetail = player.aggregations.group_by_user.buckets;
    state.userIdDetail.map((b) => {
      b.type = b.key;
      b.key = bonusType.find((c) => c.key == b.key).value;
    });
  },
  getByIpAndTypeGroup(state, player) {
    state.ipAndType = player.aggregations.group_by_user.buckets;
    state.ipAndType.map((b) => {
      b.type = b.key;
      b.key = bonusType.find((c) => c.key == b.key).value;
    });
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
