import Api from "../baseApi";

const state = {
  all: [],
  productSideProduct: [],
  detail: {},

};
const getters = {
  setting: (state) => {
    return state.setting;
  },
};
const actions = {
  getAll: async (context) => {
    let url = `/management/sideProducts`;
    let d = (await Api.get(url)).data;
    context.commit("getAll", d);
  },
  getbyProductOrOfferId: async (context, data) => {
    let url = `/management/sideProducts/byProductOrOfferId/?d=a`;
    if (data.id)
      url += `&productId=${data.id}`;
    if (data.offerId) {
      url += `&offerId=${data.offerId}`;

    }

    let d = (await Api.get(url)).data;
    context.commit("getbyProductOrOfferId", d);
  },
  getSideProductsQuery: async (context, data) => {
    let url = `/management/sideProducts/search/?d=a`;
    if (data.productId)
      url += `&productId=${data.productId}`;
    if (data.offerId) {
      url += `&offerId=${data.offerId}`;
    }
    if (data.type != null) {
      url += `&type=${data.type}`;
    }
    if (data.minSpend) {
      url += `&minSpend=${data.minSpend}`;
    }
    if (data.maxSpend) {
      url += `&maxSpend=${data.maxSpend}`;
    }

    let d = (await Api.get(url)).data;
    context.commit("getSideProductsQuery", d);
  },

  createOrUpdate: async (context, data) => {
    data = JSON.stringify(data);
    let url = `/management/sideProducts`;
    (await Api.post(url, data)).data;
  },
  delete: async (context, id) => {
    Api.delete(`/management/sideProducts/${id}`);
  },
  get: async (context, id) => {
    let url = `/management/sideProducts/${id}`;
    let data = (await Api.get(url)).data;
    context.commit("get", data);
  },
};
const mutations = {
  getAll(state, settings) {
    state.all = settings;
  },
  get(state, data) {

    state.detail = data;
  },
  getbyProductOrOfferId(state, data) {
    state.productSideProduct = data;

  },
  getSideProductsQuery(state, data) {
    state.productSideProduct = data;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
