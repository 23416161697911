<template>
  <div id="app" :key="refleshKey">
    <apexchart
      :options="chartOptions"
      :series="series"
      type="line"
      height="300"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: ["series", "chartOptions"],
  watch: {
    series: {
      handler() {
        this.renderChart();
      },
      deep: true,
    },
    chartOptions: {
      handler() {
        this.renderChart();
      },
      deep: true,
    },
  },

  data() {
    return {
      refleshKey: 0,
    };
  },
  methods: {
    renderChart() {
      this.refleshKey += 1;
    },
  },
};
</script>

