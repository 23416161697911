import Api from "./baseApi";
import bonusType from "@/lib/bonusType";

const state = {
  all: [],
  registerUsersGroupTotal: [],
  registerUsersGroup: [],
  bonusesGroup: [],
  playersOutDetail: [],
  userTotals: [],
  userTotalsBan: [],
  userTotalByChips: [],
  selectEnvironment: { id: "test", key: "Test" },
  player: {},
  enumTypes: {},
};
const getters = {
  player: (state) => {
    return state.player;
  },
  getEnv: () => {
    const env = JSON.parse(localStorage.getItem("selectEnvironment"));
    if (env) {
      return env.id;
    }
    return null;
  },
};
const actions = {
  getEnumTypes: async (context) => {
    let d = (await Api.get(`/management/EnumTypes`)).data;
    context.commit("getEnumTypes", d);
  },

  getGroupSnIdUserTotal: async (context) => {
    let d = (await Api.get(`/management/users/getGroupSnIdUserTotal/-999`))
      .data;
    context.commit("getGroupSnIdUserTotal", d);
  },
  getGroupSnIdUserTotalBan: async (context) => {
    let d = (await Api.get(`/management/users/getGroupSnIdUserTotal/-2`)).data;
    context.commit("getGroupSnIdUserTotalBan", d);
  },
  getUserTotalByChip: async (context) => {
    let d = (await Api.get(`management/users/getUserTotalByChip`)).data;
    context.commit("getUserTotalByChip", d);
  },
  getRegisterUserCount: async (context, params) => {
    let query = {
      size: 0,
      query: {
        bool: {
          must: [
            {
              range: {
                registerDate: {
                  gte: params.startDate,
                },
              },
            },
          ],
        },
      },
      aggs: {
        group_by_user: {
          terms: {
            size: 1000,
            field: "deviceInfo.os.keyword",
            missing: "N/A",
          },
          aggs: {
            platformInfo: {
              terms: {
                field: "platformInfo.snId",
              },
            },
          },
        },
      },
    };
    let d = (
      await Api.post(
        `https://poker.restplay.com/elastic/search/log_users*`,
        query
      )
    ).data;
    context.commit("getRegisterUserCount", d);
  },
  getBonuesCount: async (context, params) => {
    let query = {
      size: 0,
      query: {
        bool: {
          must: [
            {
              range: {
                createdTime: {
                  gte: params.startDate,
                },
              },
            },
          ],
        },
      },
      aggs: {
        group_by_user: {
          terms: {
            size: 1000,
            field: "type",
            missing: 0,
          },
          aggs: {
            total: {
              sum: {
                field: "chip",
              },
            },
          },
        },
      },
    };
    let d = (
      await Api.post(
        `https://poker.restplay.com/elastic/search/log_bonuses*`,
        query
      )
    ).data;
    context.commit("getBonuesCount", d);
  },
};
const mutations = {
  getGroupSnIdUserTotal(state, data) {
    state.userTotals = data;
  },

  getEnumTypes(state, data) {
    state.enumTypes = data;
  },

  getGroupSnIdUserTotalBan(state, data) {
    state.userTotalsBan = data;
  },
  getUserTotalByChip(state, data) {
    data.forEach((item) => {
      item.leftTime = Math.round(
        (new Date().getTime() - item.playerOnline.l * 1000) / 1000
      );
    });

    state.userTotalByChips = data;
  },
  getRegisterUserCount(state, player) {
    state.registerUsersGroupTotal = [];
    player.aggregations.group_by_user.buckets.forEach((val) => {
      val.platformInfo.buckets.forEach((element) => {
        let data = state.registerUsersGroupTotal.find(
          (m) => m.key == element.key
        );
        if (data != null) {
          data.value += element.doc_count;
        } else
          state.registerUsersGroupTotal.push({
            key: element.key,
            value: element.doc_count,
          });
      });
    });

    state.registerUsersGroup = player.aggregations.group_by_user.buckets;
  },
  getBonuesCount(state, player) {
    state.bonusesGroup = player.aggregations.group_by_user.buckets;
    state.bonusesGroup.map((b) => {
      b.key = bonusType.find((c) => c.key == b.key)?.value;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
