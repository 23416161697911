<template>
  <div id="app">
    <v-toolbar :color="toolbarColor" flat dark>
      <v-toolbar-title>
        <span class="yellow--text" style="margin-right: 1px"
          >{{ header }}: $
          {{ this.data.reduce((a, b) => a + Number(b), 0) | formatMoney }}</span
        >
      </v-toolbar-title>
    </v-toolbar>
    <apexchart
      :options="chartOptions"
      :series="series"
      type="bar"
      height="350"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    toolbarColor: {
      type: String,
      default: "blue darken-4",
    },
    header: {
      type: String,
      default: "Sales",
    },
    dataLabels: {
      default: false,
    },
  },
  data() {
    return {
      series: [
        {
          name: "",
          data: this.data,
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "bar",

          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
            dataLabels: {
              position: "top",
            },
          },
        },

        dataLabels: {
          enabled: this.dataLabels,
          formatter: function (value) {
            return `$${value
              .toFixed(1)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              .replace(".0", "")}`;
          },
          offsetY: -30,
          style: {
            fontSize: "14px",
            colors: ["#ffffff"],
          },
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: this.categories,
          labels: {
            style: {
              colors: [
                "#fff",
                "#fff",
                "#fff",
                "#fff",
                "#fff",
                "#fff",
                "#fff",
                "#fff",
                "#fff",
                "#fff",
                "#fff",
                "#fff",
                "#fff",
                "#fff",
                "#fff",
                "#fff",
                "#fff",
                "#fff",
                "#fff",
                "#fff",
                "#fff",
                "#fff",
                "#fff",
                "#fff",
              ],
              fontSize: "12px",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: ["#fff"],
              fontSize: "12px",
            },
            formatter: function (value) {
              return `$${value
                .toFixed(1)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                .replace(".0", "")}`;
            },
          },
        },
        tooltip: {
          followCursor: false,
          fillSeriesColor: false,
          theme: "dark",
          marker: {
            show: true,
          },

          y: {
            formatter: function (value) {
              return `$${value
                .toFixed(1)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                .replace(".0", "")}`;
            },
          },
        },
      },
    };
  },
};
</script>
