import Api from "./baseApi";

const state = {
  all: [],
  detail: {},
  cash: 0,
  redirectServer: {},
};
const getters = {
  setting: (state) => {
    return state.setting;
  },
};
const actions = {
  getAll: async (context) => {
    let url = `/management/dealer`;
    let d = (await Api.get(url)).data;
    context.commit("getAll", d);
  },
  update: async (context, data) => {
    let url = `/management/dealer/${data.id}/${data.value}`;
    let d = (await Api.put(url, data)).data;
    context.commit("update", d);
  },
  get: async (context, id) => {
    context.commit("get", id);
  },

};
const mutations = {
  getRedirect(state, settings) {
    state.redirectServer = settings;
  },
  getAll(state, settings) {
    state.cash = settings.cash.reduce((a, b) => a + b.total, 0);
    state.jackPot = settings.jackPot.reduce((a, b) => a + b.total, 0);
    state.all = settings;
  },
  get(state, id) {
    state.detail = state.all.find((m) => m.id == id);
  },
  delete(state, id) {
    state.all = state.all.filter((m) => m.id != id);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
