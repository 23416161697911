import Api from "./baseApi";

const state = {
  all: [],
  versions: [],
  detail: {},
};
const getters = {
  setting: (state) => {
    return state.setting;
  },
};
const actions = {
  getAll: async (context) => {
    let url = `/management/apis`;
    let d = (await Api.get(url)).data;
    context.commit("getAll", d);
  },
  getAllVersions: async (context) => {
    let url = `/management/versions`;
    let d = (await Api.get(url)).data;
    context.commit("getAllVersions", d);
  },
  updateVersion: async (context, data) => {
    let url = `/management/versions/${data.id || data.version}`;
    (await Api.put(url, data)).data;
  },
  updateAllVersion: async (context, data) => {
    let url = `/management/versions`;
    (await Api.post(url, data)).data;
  },
  update: async (context, data) => {
    let url = `/management/apis/${data.name}`;
    let d = (await Api.put(url, data)).data;
    context.commit("update", d);
  },
  post: async (context, data) => {
    let url = `/management/apis`;
    (await Api.post(url, data)).data;
  },
  get: async (context, id) => {
    context.commit("get", id);
  },
};
const mutations = {
  getAll(state, settings) {
    state.all = settings;
  },
  getAllVersions(state, settings) {
    state.versions = settings;
  },
  get(state, id) {
    state.detail = state.all.find((m) => m.id == id);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
