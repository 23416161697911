export default [
  { value: "Any", key: -1 },
  { value: "Holdem", key: 0 },
  { value: "Omaha", key: 1 },
  { value: "Trio", key: 2 },
  { value: "HighCardFlush", key: 3 },
  { value: "Ultimate", key: 4 },
  { value: "KingOfTable", key: 5 },
  { value: "KingOfOmaha", key: 6 },
  { value: "KingRestRoyal", key: 7 },
  { value: "KingRoyal", key: 8 },
  { value: "KingRest", key: 9 },
  { value: "Turbo", key: 10 },
  { value: "Royal", key: 11 },
  { value: "RestRoyal", key: 12 },
  { value: "Rest", key: 13 },
  { value: "Black", key: 14 },
  { value: "Red", key: 15 },
  { value: "Jump", key: 16 },
  { value: "Spin", key: 17 },
  { value: "Arena", key: 18 },
  { value: "BingoTexas", key: 19 },
  { value: "BingoOmaha", key: 20 },
  { value: "BingoTrio", key: 21 },
  { value: "Joker", key: 22 },
  { value: "HoldemBonus", key: 23 },
  { value: "Ridem", key: 24 },
  { value: "BlackJack", key: 25 },
  { value: "HoldemBet", key: 26 },
  { value: "Holdem357", key: 27 },
  { value: "CasinoHoldem", key: 28 },
  { value: "CaribbeanStud", key: 29 },
  { value: "CaribbeanHoldem", key: 30 },
  { value: "WildCardStud", key: 31 },
  { value: "DragonTiger", key: 32 },
  { value: "Baccarat", key: 33 }
];
