import Api from "../baseApi";

const state = {
  all: [],
};
const getters = {
  setting: (state) => {
    return state.setting;
  },
};
const actions = {
  getAll: async (context, params) => {
    let url = `/management/SitBlocks?p=${params.page}&t=100`;
    if (params.options.sortBy[0])
      url += `&sortBy=${params.options.sortBy[0]}&sortDesc=${params.options.sortDesc[0]}`;
    if (params.search)
      url += `&q=${params.search}`;
    let d = (await Api.get(url)).data;
    context.commit("getAll", d);
    return d;
  },
  add: async (context, params) => {
    return (
      await Api.post(
        `/management/SitBlocks/${params.fromId}/${params.toId}`
      )
    ).data;
  },
  remove: async (context, params) => {
    return (
      await Api.delete(
        `/management/SitBlocks/${params.fromId}/${params.toId}`
      )
    ).data;
  },
};
const mutations = {
  getAll(state, settings) {
    state.all = settings;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
