<!-- eslint-disable no-undef -->
<template>
  <div>
    <div class="row">
      <v-col cols="12" md="2">
        <h2>Active Players</h2>
        <div :key="renderKey">
          <PieChart
            :series="activePlatformChartData"
            :labels="activePlatformChartLabel"
          />
        </div>
      </v-col>
      <v-col cols="12" md="5">
        <h2>Total Users</h2>

        <div class="d-flex justify-space-between">
          <div class="text-nowrap">
            <b> Total Chip: </b>
            {{
              $store.state.homes.userTotals.reduce((a, b) => a + b.totalChip, 0)
                | formatMoney
            }}
          </div>
          <div class="text-nowrap">
            <b> Total Gold: </b>
            {{
              $store.state.homes.userTotals.reduce((a, b) => a + b.totalGold, 0)
                | formatMoney
            }}
          </div>
          <div class="text-nowrap">
            <b> Total User: </b>
            {{
              $store.state.homes.userTotals.reduce((a, b) => a + b.totalUser, 0)
                | formatMoney
            }}
          </div>
          <div class="text-nowrap">
            <b> Avg User Chip: </b>
            {{
              ($store.state.homes.userTotals.reduce(
                (a, b) => a + b.totalChip,
                0
              ) /
                $store.state.homes.userTotals.reduce(
                  (a, b) => a + b.totalUser,
                  0
                ))
                | formatMoney
            }}
          </div>
        </div>

        <div class="text-nowrap">
          <b style="width: 110px; display: inline-block"> Platform</b>
          <b style="width: 80px; display: inline-block">UserTotal</b>
          <b style="width: 190px; display: inline-block">TotalChip</b>
          <b style="width: 190px; display: inline-block">TotalGold</b>
        </div>
        <div
          class="text-nowrap"
          :key="index"
          v-for="(item, index) in $store.state.homes.userTotals"
        >
          <span style="width: 110px; display: inline-block">
            {{ item.snId | ToSn }}:</span
          >
          <span style="width: 80px; display: inline-block">{{
            item.totalUser
          }}</span>
          <span style="width: 190px; display: inline-block">{{
            item.totalChip | formatMoney
          }}</span>
          <span style="width: 190px; display: inline-block">{{
            item.totalGold | formatMoney
          }}</span>
        </div>
      </v-col>
      <v-col cols="12" md="5">
        <h2>Banned Users</h2>
        <div class="d-flex justify-space-between" style="gap: 0.5rem">
          <div class="text-nowrap">
            <b> Total Chip: </b>
            {{
              $store.state.homes.userTotalsBan.reduce(
                (a, b) => a + b.totalChip,
                0
              ) | formatMoney
            }}
          </div>
          <div class="text-nowrap">
            <b> Total Gold: </b>
            {{
              $store.state.homes.userTotalsBan.reduce(
                (a, b) => a + b.totalGold,
                0
              ) | formatMoney
            }}
          </div>
          <div class="text-nowrap">
            <b> Total User: </b>
            {{
              $store.state.homes.userTotalsBan.reduce(
                (a, b) => a + b.totalUser,
                0
              ) | formatMoney
            }}
          </div>
          <div class="text-nowrap">
            <b> Avg User Chip: </b>
            {{
              ($store.state.homes.userTotalsBan.reduce(
                (a, b) => a + b.totalChip,
                0
              ) /
                $store.state.homes.userTotalsBan.reduce(
                  (a, b) => a + b.totalUser,
                  0
                ))
                | formatMoney
            }}
          </div>
        </div>

        <div class="text-nowrap">
          <b style="width: 110px; display: inline-block"> Platform</b>
          <b style="width: 80px; display: inline-block">UserTotal</b>
          <b style="width: 190px; display: inline-block">TotalChip</b>
          <b style="width: 190px; display: inline-block">TotalGold</b>
        </div>
        <div
          class="text-nowrap"
          :key="index"
          v-for="(item, index) in $store.state.homes.userTotalsBan"
        >
          <span style="width: 110px; display: inline-block">
            {{ item.snId | ToSn }}:</span
          >
          <span style="width: 80px; display: inline-block">{{
            item.totalUser
          }}</span>
          <span style="width: 190px; display: inline-block">{{
            item.totalChip | formatMoney
          }}</span>
          <span style="width: 190px; display: inline-block">{{
            item.totalGold | formatMoney
          }}</span>
        </div>
      </v-col>
    </div>
    <v-row>
      <v-col cols="12" lg="6" class="pt-8">
        <MapChart
          :countryData="mapData"
          highColor="#ff0000"
          lowColor="#aaaaaa"
          countryStrokeColor="#909090"
          defaultCountryFillColor="#dadada"
        />
        <v-data-table
          :headers="headers"
          :items="countrySalesList"
          :sort-by="['total']"
          :sort-desc="[true]"
          :items-per-page="1000"
          class="elevation-1"
        >
          <template v-slot:item.name="{ item }">
            <div>{{ item.name | toCountry }}</div>
          </template>

          <template v-slot:item.uniqueUserCount="{ item }">
            <div>
              {{ item.uniqueUserCount }} / $
              {{ (item.total / item.uniqueUserCount) | formatMoney }} /
              {{ (item.totalOrder / item.uniqueUserCount) | formatMoney }}
            </div>
          </template>

          <template v-slot:item.totalChip="{ item }">
            <div>{{ item.totalChip | formatMoney }}</div>
          </template>
          <template v-slot:item.total="{ item }">
            <div>$ {{ item.total | formatMoney }}</div>
          </template>
          <template v-slot:item.avgSales="{ item }">
            <div>$ {{ (item.total / item.totalOrder) | formatMoney }}</div>
          </template>
          <template v-slot:item.avgChip="{ item }">
            <div>{{ (item.totalChip / item.totalOrder) | formatMoney }}</div>
          </template>
          <template v-slot:item.countryCode="{ item }">
            <div style="display: flex">
              <v-avatar size="36" style="margin-right: 5px; margin-top: 3px">
                <v-img
                  :src="`https://pokerface.restplay.com/flags/${item.countryCode}.png`"
                />
              </v-avatar>
              <div>
                <span>{{ item.name }}</span>
              </div>
            </div>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" lg="6" class="pt-8 pr-2">
        <v-row>
          <v-col cols="12">
            <LineChart :series="series" :chartOptions="chartOptions" />
          </v-col>
          <v-col cols="12">
            <DistributedColumnChart
              :categories="salesChartCategories"
              :data="salesChartData"
              :key="renderKey"
              toolbarColor="blue darken-4"
              header="Sales"
              dataLabels="true"
            />
          </v-col>
          <v-col cols="12">
            <DistributedColumnChart
              :categories="totalBonusCategories"
              :data="totalBonusData"
              :key="renderKey"
              toolbarColor="red darken-4"
              header="Total Bonus"
            />
          </v-col>
          <v-col cols="12" lg="3">
            <v-toolbar color="blue darken-4" flat dark>
              <v-toolbar-title>
                <span class="yellow--text" style="margin-right: 1px"
                  >Sales: $
                  {{
                    this.purchases.reduce((a, b) => a + b.total, 0)
                      | formatMoney
                  }}</span
                >
              </v-toolbar-title>
            </v-toolbar>
            <div v-for="(item, i) in this.purchases" :key="i">
              <v-card max-width="400" class="mx-auto" dark>
                <v-card-text>
                  <p class="title white--text text-center">
                    {{ item.name }} - ${{ item.total | formatMoney }}
                  </p>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12" lg="5">
            <v-toolbar color="red darken-4" flat dark>
              <v-toolbar-title>
                <span class="yellow--text" style="margin-right: 10px"
                  >Total Bonus: $
                  {{
                    this.$store.state.homes.bonusesGroup.reduce(
                      (a, b) => a + b.total.value,
                      0
                    ) | formatMoney
                  }}</span
                >
              </v-toolbar-title>
            </v-toolbar>
            <div
              v-for="(item, i) in this.$store.state.homes.bonusesGroup"
              :key="i"
            >
              <v-card max-width="400" dark>
                <div class="white--text text-center mb-1">
                  {{ item.key }} - {{ item.doc_count | formatMoney }} - $
                  {{ item.total.value | formatMoney }}
                </div>
              </v-card>
            </div>
          </v-col>

          <v-col cols="12" lg="4">
            <v-toolbar color="green darken-4" flat dark>
              <v-toolbar-title>
                <span class="yellow--text" style="margin-right: 10px"
                  >Daily New Users:
                  {{
                    this.$store.state.homes.registerUsersGroup.reduce(
                      (a, b) => a + b.doc_count,
                      0
                    ) | formatMoney
                  }}</span
                >
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text class="green darken-4">
              <p
                v-for="(item, i) in this.$store.state.homes
                  .registerUsersGroupTotal"
                :key="i"
              >
                Total {{ item.key | ToSn }} -
                <span style="color: #f4ff49">{{ item.value }}</span>
              </p>
            </v-card-text>
            <div
              v-for="(item, i) in this.$store.state.homes.registerUsersGroup"
              :key="i"
            >
              <v-card max-width="400" class="mx-auto" dark>
                <v-card-text>
                  <div class="text--primary">
                    {{ item.key }} -
                    <span style="color: #f4ff49">{{ item.doc_count }}</span>
                  </div>
                  <div v-for="(itemx, a) in item.platformInfo.buckets" :key="a">
                    {{ itemx.key | ToSn }} -
                    <span style="color: #f4ff49">{{ itemx.doc_count }}</span>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="12">
            <v-toolbar color="red darken-4" flat dark>
              <v-toolbar-title>
                <span class="yellow--text" style="margin-right: 10px"
                  >Purchase Refund By Country</span
                >
              </v-toolbar-title>
            </v-toolbar>
            <v-data-table
              :headers="headers"
              :items="countrySalesRefund"
              :sort-by="['total']"
              :sort-desc="[true]"
              :items-per-page="1000"
              class="elevation-1"
            >
              <template v-slot:item.name="{ item }">
                <div>{{ item.name | toCountry }}</div>
              </template>

              <template v-slot:item.uniqueUserCount="{ item }">
                <div>
                  {{ item.uniqueUserCount }} / $
                  {{ (item.total / item.uniqueUserCount) | formatMoney }} /
                  {{ (item.totalOrder / item.uniqueUserCount) | formatMoney }}
                </div>
              </template>

              <template v-slot:item.totalChip="{ item }">
                <div>{{ item.totalChip | formatMoney }}</div>
              </template>
              <template v-slot:item.total="{ item }">
                <div>$ {{ item.total | formatMoney }}</div>
              </template>
              <template v-slot:item.avgSales="{ item }">
                <div>$ {{ (item.total / item.totalOrder) | formatMoney }}</div>
              </template>
              <template v-slot:item.avgChip="{ item }">
                <div>
                  {{ (item.totalChip / item.totalOrder) | formatMoney }}
                </div>
              </template>
              <template v-slot:item.countryCode="{ item }">
                <div style="display: flex">
                  <v-avatar
                    size="36"
                    style="margin-right: 5px; margin-top: 3px"
                  >
                    <v-img
                      :src="`https://pokerface.restplay.com/flags/${item.countryCode}.png`"
                    />
                  </v-avatar>
                  <div>
                    <span>{{ item.name }}</span>
                  </div>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import LineChart from "@/components/Charts/LineChart";
import PieChart from "@/components/Charts/PieChart";
import DistributedColumnChart from "@/components/Charts/DistributedColumnChart";
// eslint-disable-next-line no-unused-vars
import MapChart from "vue-map-chart";

export default {
  components: {
    LineChart,
    MapChart,
    PieChart,
    DistributedColumnChart,
  },
  watch: {
    activePlayer: {
      handler() {
        this.renderChart();
      },
      deep: true,
    },
    purchases: {
      handler() {
        this.renderChart();
      },
      deep: true,
    },
    totalBonus: {
      handler() {
        this.renderChart();
      },
      deep: true,
    },
  },
  data() {
    return {
      mapData: {},
      countrySalesList: [],
      series: [
        {
          name: "Sales",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 300,
          type: "line",
          toolbar: {
            show: false,
          },
          background: "#1B5E20",
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          colors: "#FDD835",
        },
        stroke: {
          curve: "straight",
          colors: ["#ffffff"],
        },
        title: {
          text: "Daily Sales Last 30 Day",
          align: "left",
          style: {
            fontSize: "16px",
            fontWeight: "bold",
            color: "#FDD835",
          },
        },
        grid: {
          show: true,
          borderColor: "#eee",
        },
        yaxis: {
          labels: {
            show: true,
            style: {
              colors: ["#ffffff"],
              fontSize: "14px",
              fontWeight: "900",
            },
            formatter: function (value) {
              return `${value.toFixed(0)}`;
            },
          },
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
            style: {
              colors: [
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
              ],
              fontSize: "12px",
              fontWeight: "900",
            },
          },
        },
        tooltip: {
          followCursor: false,
          fillSeriesColor: false,
          theme: "dark",
          marker: {
            show: false,
          },

          y: {
            formatter: function (value) {
              return `$${value.toFixed(0)}`;
            },
          },
        },
      },
      countrySalesCompleted: [],
      countrySalesRefund: [],
      purchases: [],
      headers: [
        { text: "Location", value: "countryCode" },
        { text: "Total Sales", value: "total" },
        { text: "Avg Sales", value: "avgSales" },
        {
          text: "U.User Count / Per Sales / Avg Orders",
          value: "uniqueUserCount",
        },
        { text: "Total Orders", value: "totalOrder" },
        { text: "Total Chip", value: "totalChip" },
        { text: "Avg Chip", value: "avgChip" },
      ],
      activePlayer: this.$store.state.players.all,
      renderKey: 0,
      activePlatformChartData: [],
      activePlatformChartLabel: [],
      salesChartCategories: [],
      salesChartData: [],
      totalBonus: this.$store.state.homes.bonusesGroup,
      totalBonusCategories: [],
      totalBonusData: [],
    };
  },
  methods: {
    async initialize() {
      let dt = new Date();
      dt.setDate(dt.getDate() - 1);
      let date = Math.round(dt.getTime() / 1000);
      this.$store.dispatch("homes/getRegisterUserCount", {
        startDate: date,
      });
      await this.$store.dispatch("players/getPlayers", {
        flag: "",
        isBot: -1,
        isDisconnect: -1,
        isSitIn: -1,
        options: {
          groupBy: [],
          groupDesc: [],
          itemsPerPage: 50,
          multiSort: false,
          mustSort: false,
          page: 1,
          sortBy: [],
          sortDesc: [false],
        },
        os: "",
        page: 0,
        search: "",
      });
      this.activePlayer = this.$store.state.players.all;
      this.setActivePlayer();
      await this.$store.dispatch("homes/getGroupSnIdUserTotal");
      await this.$store.dispatch("homes/getGroupSnIdUserTotalBan");
      await this.$store.dispatch("purchases/getByDailySales", {
        startDate: Math.round(new Date().addDays(-29).getTime() / 1000),
        endDate: Math.round(new Date().getTime() / 1000),
      });
      this.chartOptions.xaxis.categories =
        this.$store.state.purchases.dailySales.map((m) => m.name.split("-")[2]);
      this.series[0].data = [
        ...this.$store.state.purchases.dailySales.map((m) => m.total),
      ];
      this.countrySalesList = await this.$store.dispatch(
        "purchases/getByCountrySales",
        "completed"
      );
      this.countrySalesRefund = await this.$store.dispatch(
        "purchases/getByCountrySales",
        "refund"
      );
      this.mapData = this.$store.state.purchases.countrySales;
      await this.$store.dispatch("homes/getBonuesCount", {
        startDate: date,
      });

      this.setTotalBonus();

      await this.$store.dispatch("purchases/getPlatformTotalSales");
      this.purchases = this.$store.state.purchases.platformTotalSales;
      this.setPurchases();
    },
    setActivePlayer() {
      for (
        let i = 0;
        i < this.$store.state.players.all.deviceGroup.length;
        i++
      ) {
        this.activePlatformChartLabel[i] =
          this.$store.state.players.all.deviceGroup[i].key;
        this.activePlatformChartData[i] =
          this.$store.state.players.all.deviceGroup[i].total;
      }
    },
    setPurchases() {
      for (let i = 0; i < this.purchases.length; i++) {
        this.salesChartData[i] = this.purchases[i].total.toFixed(1);
        this.salesChartCategories[i] =
          this.purchases[i].name == null ? "" : this.purchases[i].name;
      }
    },
    setTotalBonus() {
      for (let i = 0; i < this.$store.state.homes.bonusesGroup.length; i++) {
        this.totalBonusData[i] =
          this.$store.state.homes.bonusesGroup[i]?.total?.value;
        this.totalBonusCategories[i] =
          this.$store.state.homes.bonusesGroup[i]?.key == null
            ? ""
            : this.$store.state.homes.bonusesGroup[i]?.key;
      }
    },

    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    renderChart() {
      this.renderKey += 1;
    },
  },

  async mounted() {
    await this.initialize();
  },
};
</script>
<style>
.vue-map-legend {
  color: #000;
}
.vue-world-map {
  height: initial !important;
}
</style>
