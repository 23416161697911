import Api from "./baseApi";

const state = {
  all: [],
  detail: {},
  redirectServer: {},
};
const getters = {
  setting: (state) => {
    return state.setting;
  },
};
const actions = {
  getAll: async (context) => {
    let url = `/management/servers`;
    let d = (await Api.get(url)).data;
    context.commit("getAll", d);
  },
  update: async (context, data) => {
    let url = `/management/servers/${data.id}`;
    let d = (await Api.put(url, data)).data;
    context.commit("update", d);
  },
  delete: async (context, data) => {
    let url = `/management/servers/${data.id}`;
    (await Api.delete(url, data)).data;
    context.commit("delete", data.id);
  },
  get: async (context, id) => {
    context.commit("get", id);
  },
  getRedirect: async (context, data) => {
    var res = (await Api.get(`${data.url}/api/Deployment/redirect`, data)).data;
    context.commit("getRedirect", res);
  },
  postRedirect: async (context, data) => {
    let url = `${data.url}/api/Deployment/redirect`;
    (await Api.post(url, data)).data;
  },
};
const mutations = {
  getRedirect(state, settings) {
    state.redirectServer = settings;
  },
  getAll(state, settings) {
    state.all = settings.servers;
  },
  get(state, id) {
    state.detail = state.all.find((m) => m.id == id);
  },
  delete(state, id) {
    state.all = state.all.filter((m) => m.id != id);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
