import Api from "./baseApi";

const state = {
  all: [],
  setting: {},
};
const getters = {
  setting: (state) => {
    return state.setting;
  },
};
const actions = {
  getAll: async (context, params) => {
    let url = `/management/PictureUploads?p=${params.page}&t=100`;
    if (params && params.options && params.options.sortBy && params.options.sortBy[0])
      url += `&sortBy=${params.options.sortBy[0]}&sortDesc=${params.options.sortDesc[0]}`;

    let d = (await Api.get(url)).data;
    context.commit("getAll", d);
    return d;

  },
  approvePicture: async (context, id) => {
    let url = `/management/PictureUploads/ApprovePicture/${id}`;
    (await Api.get(url)).data;
    context.commit("approvePicture", id);
  },
  rejectPicture: async (context, id) => {


    let url = `/management/PictureUploads/RejectPicture/${id}`;
    (await Api.get(url)).data;
    context.commit("rejectPicture", id);
  },
};
const mutations = {
  getAll(state, settings) {
    state.all = settings;
  },
  approvePicture(state, id) {
    state.all.data = state.all.data.filter((m) => m.id != id);
    state.all.total = state.all.data.length;
  },
  rejectPicture(state, id) {
    state.all.data = state.all.data.filter((m) => m.id != id);
    state.all.total = state.all.data.length;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
