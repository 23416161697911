import Api from "../baseApi";
const state = {
  all: [],
  export: {},
  config: {},
};
const getters = {
  config: (state) => {
    return state.config;
  },
};

const actions = {
  getAll: async (context, env) => {
    let url = `/management/configs/${env}`;
    let d = (await Api.get(url)).data;
    context.commit("getConfigs", d);
  },
  getExport: async (context, env) => {
    let url = `https://config.restplay.com/${env}`;
    let d = (await Api.get(url)).data;
    return d;
  },
  postConfigs: async (context, data) => {
    (await Api.post(`/management/configs/`, data)).data;
    context.commit("postConfigs", data);
  },
  delete: async (context, id) => {
    (await Api.delete(`/management/configs/${id}`)).data;
  },
};
const mutations = {
  getConfigs(state, Configs) {
    state.all = Configs;
  },
  getExport(state, Configs) {
    state.export = Configs;
  },
  postConfigs(state, Configs) {
    state.config = Configs;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
