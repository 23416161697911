import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/lobby",
    name: "lobby",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/lobby/Index.vue"),
  },
  {
    path: "/topusers",
    name: "topusers",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/users/TopUsers.vue"),
  },
  {
    path: "/logs/errors",
    name: "errors",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/errors/Index.vue"),
  },
  {
    path: "/logs/userquery",
    name: "userquery",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/logs/UserQuery.vue"),
  },

  {
    path: "/config/webgl",
    name: "webgl",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/webgl/Index.vue"),
  },
  {
    path: "/purchases",
    name: "purchases",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/purchases/Index.vue"),
  },
  {
    path: "/logs/userReports",
    name: "userReports",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/userReports/Index.vue"),
  },
  {
    path: "/logs/gameLogs",
    name: "gameLogs",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/gameLogs/Index.vue"),
  },
  {
    path: "/pictureUploads",
    name: "pictureUploads",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pictureUploads/Index.vue"),
  },
  {
    path: "/userNameChanges",
    name: "userNameChanges",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/userNameChanges/Index.vue"),
  },
  {
    path: "/rooms",
    name: "rooms",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/rooms/Index.vue"),
  },
  {
    path: "/config/apiServer",
    name: "apiServer",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/apiServer/Index.vue"),
  },
  {
    path: "/config/settings",
    name: "settings",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/settings/Index.vue"),
  },
  {
    path: "/config/swagger",
    name: "swagger",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/settings/Swagger.vue"),
  },
  {
    path: "/rooms/detail/:id",
    name: "rooms",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/rooms/Detail.vue"),
  },
  {
    path: "/players",
    name: "players",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/players/Index.vue"),
  },
  {
    path: "/logs/playersIo",
    name: "playersIo",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/players/PlayerIo.vue"),
  },
  {
    path: "/players/detail/:id",
    name: "players",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/players/Detail.vue"),
  },
  {
    path: "/config/servers",
    name: "servers",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/servers/Index.vue"),
  },
  {
    path: "/products",
    name: "products",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/products/Index.vue"),
  },
  {
    path: "/notifications",
    name: "notifications",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/notifications/Index.vue"),
  },
  {
    path: "/dynamicOffers",
    name: "dynamicOffers",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/dynamicOffers/Index.vue"),
  },

  {
    path: "/dynamicOffers/detail/:id",
    name: "dynamicOffers",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/dynamicOffers/Detail.vue"),
  },
  {
    path: "/dynamicOffers/detail",
    name: "dynamicOffers",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/dynamicOffers/Detail.vue"),
  },
  {
    path: "/logs/chatLogs",
    name: "chatLogs",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/gameLogs/chatLog.vue"),
  },
  {
    path: "/logs/specialWinner",
    name: "specialWinner",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/gameLogs/SpecialWinner.vue"),
  },
  {
    path: "/logs/bonus",
    name: "BonusDetailCard",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/logs/components/BonusDetailCard.vue"),
  },

  {
    path: "/logs/votekick",
    name: "votekick",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/gameLogs/VoteKick.vue"),
  },
  {
    path: "/logs/taxpays",
    name: "taxpays",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/users/taxPays/Index.vue"),
  },
  {
    path: "/logs/cashpays",
    name: "cashpays",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/users/cashPays/Index.vue"),
  },
  {
    path: "/logs/amountIo",
    name: "amountIo",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/logs/amountIo/Index.vue"),
  },

  {
    path: "/config/configs",
    name: "configs",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/settings/Config.vue"),
  },
  {
    path: "/config/dealer",
    name: "configs",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/settings/Dealer.vue"),
  },
  {
    path: "/botDetection",
    name: "botDetection",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/botDetection/Index.vue"),
  },
  {
    path: "/botDetectionv2",
    name: "botDetectionv2",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/botDetection/IndexV2.vue"),
  },
  {
    path: "/logs/feedbacks",
    name: "feedbacks",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/users/feedbacks/Index.vue"),
  },
  {
    path: "/logs/deletionAccount",
    name: "deletionAccount",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/users/deletionAccounts/Index.vue"),
  },
  {
    path: "/logs/chipTransfers",
    name: "chipTransfers",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/users/components/ChipDetailCard.vue"),
  },
  {
    path: "/users/sitBlocks",
    name: "sitBlocks",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/users/sitBlocks/Index.vue"),
  },

  {
    path: "/products/detail/:id",
    name: "products",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/products/Detail.vue"),
  },
  {
    path: "/products/detail",
    name: "productsdetail",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/products/Detail.vue"),
  },
  {
    path: "/salesTargets",
    name: "salesTargets3",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/products/salesTargets/Index.vue"),
  },

  {
    path: "/salesTargets/detail/:id",
    name: "salesTargets3",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/products/salesTargets/SalesDetail.vue"),
  },
  {
    path: "/salesTargets/detail",
    name: "salesTargets2",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/products/salesTargets/SalesDetail.vue"),
  },
  {
    path: "/products",
    name: "productsdetail2",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/products/Index.vue"),
  },

  {
    path: "/sideProducts/detail/:id",
    name: "sideProducts",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/products/sideProducts/SideDetail.vue"),
  },
  {
    path: "/sideProducts/detail",
    name: "sideProducts",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/products/sideProducts/SideDetail.vue"),
  },
  {
    path: "/sideProducts",
    name: "sideProducts23",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/products/sideProducts/Index.vue"),
  },
  {
    path: "/promoCodes",
    name: "promoCodes",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/promoCodes/Index.vue"),
  },
  {
    path: "/promoCodes/detail/:id",
    name: "promoCodes2",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/promoCodes/Detail.vue"),
  },
  {
    path: "/promoCodes/detail",
    name: "promoCodesdetail",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/promoCodes/Detail.vue"),
  },
  {
    path: "/notifications/detail/:id",
    name: "notifications",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/notifications/Detail.vue"),
  },
  {
    path: "/notifications/detail",
    name: "notifications",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/notifications/Detail.vue"),
  },
  {
    path: "/config/servers/detail/:id",
    name: "servers",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/servers/Detail.vue"),
  },
  {
    path: "/sign-in",
    name: "signin",
    component: () => import("../views/Signin.vue"),
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.authRequired)) {
    if (!store.state.isAuthenticated) {
      next({
        path: "/sign-in",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
