<template>
  <v-app id="inspire">
    <v-alert
      :value="$store.state.isAlert"
      width="300"
      :dismissible="true"
      transition="scale-transition"
      style="position: fixed; top: 0; right: 0; z-index: 999999"
      type="success"
    >
      {{ $store.state.alertMessage }}
    </v-alert>
    <v-navigation-drawer v-if="isAuthenticated" v-model="drawer" app clipped>
      <v-list dense>
        <v-list-item to="/">
          <v-list-item-action>
            <v-icon>home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/lobby">
          <v-list-item-action>
            <v-icon>mdi-bank</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Lobby</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/rooms">
          <v-list-item-action>
            <v-icon>mdi-domain</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Rooms</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/players">
          <v-list-item-action>
            <v-icon>mdi-account</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Players</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/purchases">
          <v-list-item-action>
            <v-badge
              v-if="$store.state.notifications.notificationBadge.purchases"
              color="green"
              :content="$store.state.notifications.notificationBadge.purchases"
            ></v-badge>
            <v-icon>mdi-cash</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Purchases</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/products">
          <v-list-item-action>
            <v-icon>mdi-shopping</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>products</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/sideProducts">
          <v-list-item-action>
            <v-icon>mdi-shopping-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>sideProducts</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/salesTargets">
          <v-list-item-action>
            <v-icon>mdi-target</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>salesTargets</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/promoCodes">
          <v-list-item-action>
            <v-icon>mdi-gift-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>promoCodes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/dynamicOffers">
          <v-list-item-action>
            <v-icon>mdi-offer</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>dynamicOffers</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/notifications">
          <v-list-item-action>
            <v-icon>mdi-bell</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Notification</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/pictureUploads">
          <v-list-item-action>
            <v-badge
              v-if="$store.state.pictureUploads.all.total"
              color="green"
              :content="$store.state.pictureUploads.all.total"
            ></v-badge>
            <v-icon>mdi-account-arrow-left</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Picture Uploads</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/userNameChanges">
          <v-list-item-action>
            <v-badge
              v-if="$store.state.userNameChanges.all.total"
              color="green"
              :content="$store.state.userNameChanges.all.total"
            ></v-badge>
            <v-icon>mdi-account-arrow-left</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>UserName Changes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/topusers">
          <v-list-item-action>
            <v-icon>mdi-account</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Top Users</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/botDetection">
          <v-list-item-action>
            <v-icon>mdi-robot</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Bot Detection</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/botDetectionv2">
          <v-list-item-action>
            <v-icon>mdi-robot-angry</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Bot Detection V2</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/users/sitBlocks">
          <v-list-item-action>
            <v-icon>mdi-account-cancel</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Sit Blocks</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group group="logs" prepend-icon="mdi-view-dashboard">
          <template v-slot:activator>
            <v-list-item-title>Logs</v-list-item-title>
          </template>
          <v-list-item to="/logs/amountIo">
            <v-list-item-action>
              <v-icon>mdi-cash</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Amount IO</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/logs/bonus">
            <v-list-item-action>
              <v-icon>mdi-cog-clockwise</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Bonus</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/logs/specialWinner">
            <v-list-item-action>
              <v-icon>mdi-cash</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Special Winners</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/logs/gameLogs">
            <v-list-item-action>
              <v-icon>mdi-alarm</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Game Logs</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/logs/deletionAccount">
            <v-list-item-action>
              <v-icon>mdi-timeline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Deletion Account</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/logs/chatLogs">
            <v-list-item-action>
              <v-icon>mdi-timeline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Chat Logs</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/logs/votekick">
            <v-list-item-action>
              <v-icon>mdi-poll</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Vote Kick</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/logs/playersIo">
            <v-list-item-action>
              <v-icon>mdi-account</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Players IO</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/logs/userReports">
            <v-list-item-action>
              <v-icon>mdi-waves</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>User Reports</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/logs/chipTransfers">
            <v-list-item-action>
              <v-icon>mdi-timetable</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Chip Transfers</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/logs/taxpays">
            <v-list-item-action>
              <v-icon>mdi-cash</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Tax Pays</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/logs/cashpays">
            <v-list-item-action>
              <v-icon>mdi-cash</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Cash Pays</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/logs/userquery">
            <v-list-item-action>
              <v-icon>mdi-oar</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>User Query</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/logs/feedbacks">
            <v-list-item-action>
              <v-icon>mdi-cog-clockwise</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Feedbacks</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/logs/errors">
            <v-list-item-action>
              <v-icon>mdi-oar</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Errors</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group group="config" prepend-icon="mdi-abacus">
          <template v-slot:activator>
            <v-list-item-title>Config</v-list-item-title>
          </template>
          <v-list-item to="/config/dealer">
            <v-list-item-action>
              <v-icon>mdi-cog-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Dealer</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/config/settings">
            <v-list-item-action>
              <v-icon>mdi-cog-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Settings</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item to="/config/apiServer">
            <v-list-item-action>
              <v-icon>mdi-call-split</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Version</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->

          <v-list-item to="/config/webgl">
            <v-list-item-action>
              <v-icon>mdi-arrow-decision-auto</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>WebGL</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/config/servers">
            <v-list-item-action>
              <v-icon>mdi-cog-clockwise</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Server</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/config/configs">
            <v-list-item-action>
              <v-icon>mdi-cog-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Remote Config</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/config/swagger">
            <v-list-item-action>
              <v-icon>mdi-arrow-decision-auto</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Swagger</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item>
          <v-list-item-action>
            <v-icon>mdi-baby-bottle-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <a
              href="http://gf.restplay.net/d/VNs-Wv4Gz/restplay?orgId=1&refresh=5s&from=1607623553465&to=1607623853465"
              target="_blank"
              style="text-decoration: none; color: white"
            >
              <v-list-item-title>Grafana</v-list-item-title>
            </a>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-progress-linear
      v-if="$store.state.progress"
      indeterminate
      color="orange"
      style="position: fixed; top: 1px; z-index: 99999"
    ></v-progress-linear>
    <v-app-bar
      app
      v-if="isAuthenticated"
      :color="env != 'Prod' ? 'blue' : ''"
      clipped-left
    >
      <v-app-bar-nav-icon @click.stop="clickSideBar" />
      <v-toolbar-title>Poker Management - {{ env }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field
        flat
        solo-inverted
        prepend-icon="search"
        label="Search"
        v-model="userId"
        v-on:keyup.enter="clicked = !clicked"
        class="hidden-sm-and-down pt-6"
      ></v-text-field>
      <UserDetailModalCard :clicked="clicked" :userId="userId" />

      <v-spacer></v-spacer>
      <div v-if="!isAuthenticated">
        <v-btn to="/sign-in" data-cy="signinBtn">SIGN IN</v-btn>
      </div>
      <div v-else>
        <v-row justify="space-around">
          <v-col cols="2">
            <v-combobox
              :items="environments"
              v-model="$store.state.homes.selectEnvironment"
              @change="changeEnv"
              item-value="id"
              item-text="key"
              hide-details
              label="Environment"
            ></v-combobox>
          </v-col>
          <v-col cols="6">
            <v-combobox
              :items="servers"
              v-model="selectServer"
              @change="changeServer"
              item-value="id"
              item-text="machineName"
              hide-details
              label="Server"
            ></v-combobox>
          </v-col>

          <v-col cols="4">
            <v-btn @click="logout" data-cy="logout">Logout</v-btn>
          </v-col>
        </v-row>
      </div>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view v-if="$store.state.homes.enumTypes.sideProductType" />
      </v-container>
    </v-main>
    <v-footer app>
      <span>RestPlay&copy; 2019</span>
    </v-footer>
  </v-app>
</template>
<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
// import AppNavigation from "@/components/AppNavigation";
import { firebase } from "@firebase/app";
import "firebase/auth";

export default {
  name: "home",
  components: {
    UserDetailModalCard: () =>
      import("@/components/Users/UserDetailModalCard.vue"),
    // AppNavigation
  },
  props: {
    source: String,
  },
  data: () => ({
    userId: "",
    clicked: false,
    dialog: false,
    servers: [],
    environments: [
      { id: "test", key: "Test", url: process.env.VUE_APP_API_TEST_SERVER },
      { id: "prod", key: "Prod", url: process.env.VUE_APP_API_SERVER },
    ],
    env: JSON.parse(localStorage.getItem("selectEnvironment")).key,
    drawer: false,
    selectServer: null,
    logsActive: false,
  }),
  computed: {
    isAuthenticated() {
      let that = this;
      firebase.auth().onAuthStateChanged(function (user) {
        if (!user && window.Vue.$route.path != "/sign-in") {
          that.$store.dispatch("userSignOut");
        }
      });

      return this.$store.getters.isAuthenticated;
    },
  },
  // firestore() {
  //   return {
  //     pictureUploads: db.collection("pictureUploads"),
  //     notification: db.collection("settings").doc("notification"),
  //     servers: db.collection("servers").where("url", ">", ""),
  //   };
  // },
  methods: {
    logout() {
      this.$store.dispatch("userSignOut");
    },
    changeServer(data) {
      this.$store.dispatch("changeServer", data);
    },
    changeEnv(val) {
      localStorage.setItem("selectEnvironment", JSON.stringify(val));
      this.env = val.key;
      location.reload();
    },
    clickSideBar() {
      this.drawer = !this.drawer;
      localStorage.setItem("drawer", this.drawer);
    },
  },

  async created() {
    await this.$store.dispatch("homes/getEnumTypes");

    this.drawer = localStorage.getItem("drawer") == "true";
    // eslint-disable-next-line no-console
    console.log(localStorage.getItem("drawer"));
    const env = JSON.parse(localStorage.getItem("selectEnvironment"));
    if (localStorage.getItem(env.id + ".currentServer")) {
      this.selectServer = JSON.parse(
        localStorage.getItem(env.id + ".currentServer")
      );
    }

    if (localStorage.getItem("selectEnvironment")) {
      this.$store.state.homes.selectEnvironment = JSON.parse(
        localStorage.getItem("selectEnvironment")
      );
    }

    this.$vuetify.theme.dark = true;
    await this.$store.dispatch("servers/getAll");
    this.servers = this.$store.state.servers.all.filter((m) => m.url != null);
    setInterval(async () => {
      await this.$store.dispatch("notifications/checkNotificationCount");
      await this.$store.dispatch("pictureUploads/getAll", { page: 0 });
      await this.$store.dispatch("userNameChanges/getAll", { page: 0 });
    }, 1000 * 60 * 1);
  },
};
</script>

<style lang="scss">
.v-data-table td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.v-data-table tr:nth-child(2n) {
  background: #00000036;
}
.v-data-table thead th {
  background: #0000005c !important;
}
.my-awesome-json-theme {
  background: #6b636b33;
  white-space: nowrap;
  color: #fff;
  font-size: 14px;
  font-family: Consolas, Menlo, Courier, monospace;

  .jv-ellipsis {
    color: #999;
    background-color: #eee;
    display: inline-block;
    line-height: 0.9;
    font-size: 0.9em;
    padding: 0px 4px 2px 4px;
    border-radius: 3px;
    vertical-align: 2px;
    cursor: pointer;
    user-select: none;
  }
  .jv-button {
    color: #49b3ff;
  }
  .jv-key {
    color: #fff;
  }
  .jv-item {
    &.jv-array {
      color: #111111;
    }
    &.jv-boolean {
      color: #fc1e70;
    }
    &.jv-function {
      color: #067bca;
    }
    &.jv-number {
      color: #fc1e70;
    }
    &.jv-object {
      color: #fff;
    }
    &.jv-undefined {
      color: #e08331;
    }
    &.jv-string {
      color: #42b983;
      word-break: break-word;
      white-space: normal;
    }
  }
  .jv-code {
    .jv-toggle {
      &:before {
        padding: 0px 2px;
        border-radius: 2px;
      }
      &:hover {
        &:before {
          background: #eee;
        }
      }
    }
  }
}

.v-tooltip__content {
  opacity: 1 !important;
}
.jv-container {
  background: #0000005c !important;
}
</style>
