<template>
  <div id="app" :key="renderKey">
    <h2 style="text-align: center">{{ header }}</h2>
    <apexchart
      type="pie"
      width="100%"
      height="440"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    header: {
      type: String,
    },
    series: {
      type: Array,
      required: true,
    },
    labels: {
      type: Array,
      required: true,
    },
  },
  watch: {
    series: {
      handler() {
        this.renderChart();
      },
      deep: true,
    },
  },
  data() {
    return {
      renderKey: 0,
      chartOptions: {
        chart: {
          width: 300,
          type: "pie",
        },
        labels: this.labels,
        legend: {
          show: true,
          position: "bottom",
          fontSize: "14px",
          labels: {
            colors: "#fff",
          },
          formatter: function (seriesName, opts) {
            return [
              seriesName,
              " - ",
              opts.w.globals.series[opts.seriesIndex]
                ?.toFixed(1)
                ?.replace(/\d(?=(\d{3})+\.)/g, "$&,")
                ?.replace(".0", ""),
            ];
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "top",
              },
            },
          },
        ],
      },
    };
  },
  methods: {
    renderChart() {
      this.renderKey++;
    },
  },
};
</script>
