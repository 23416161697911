export default [
  { value: "Daily", key: 1 },
  { value: "Lucky", key: 2 },
  { value: "Wheel", key: 3 },
  { value: "Weekly", key: 4 },
  { value: "ComeBack", key: 5 },
  { value: "Level", key: 6 },
  { value: "Video", key: 7 },
  { value: "Gift", key: 8 },
  { value: "RestGift", key: 9 },
  { value: "Challenge", key: 10 },
  { value: "ChallengeStage", key: 11 },
  { value: "LuckyHand", key: 12 },
  { value: "Achieved", key: 13 },
  { value: "VideoStreak", key: 14 },
  { value: "WeeklyVideoBoost", key: 15 },
  { value: "LeagueUp", key: 16 },
  { value: "ShopCardSpin", key: 17 },
  { value: "TicketCase", key: 18 },
  { value: "NewYear", key: 19 },
  { value: "Purchase", key: 20 },
  { value: "AchievedCase", key: 21 },
  { value: "VideoChallenge", key: 22 },
  { value: "Refferance", key: 23 },
  { value: "LuckyVideo", key: 24 },
  { value: "TaxPay", key: 25 },
  { value: "CashPay", key: 26 },
  { value: "DailyVideo", key: 27 },
  { value: "JackPotSitInGo", key: 28 },
  { value: "JackPotVideoPoker", key: 29 },
  { value: "JackPotHilo", key: 30 },
  { value: "WinSitInGo", key: 31 },
  { value: "WinVideoPoker", key: 32 },
  { value: "WinHilo", key: 33 },
  { value: "SpecialCard", key: 34 },
  { value: "PlayOffPrize", key: 35 },
  { value: "PromoCode", key: 36 },
  { value: "Lottery", key: 37 },
  { value: "CoinFlip", key: 38 },
  { value: "Rush", key: 39 },
  { value: "TrioWheel", key: 40 },
  { value: "LuckyWheel", key: 41 },
];
