import Api from "../baseApi";
const state = {
  all: [],
  error: {},
  versions: [],
  select: { id: -1, value: "All" },
  playerSelect: { id: -1, value: "All" },
  platformSelect: { id: -1, value: "All" },
  sitSelect: { id: -1, value: "All" },
  pageItemSelect: { id: 100, value: "100" },
  timeSelect: { id: 1440, value: "Last 24 hours" },
};
const getters = {
  error: (state) => {
    return state.error;
  },
};
const actions = {
  getErrors: async (context, params) => {
    var query = {
      query: {
        bool: {
          must: [],
        },
      },
      aggs: {
        condition: {
          terms: {
            field: "condition.keyword",
            order: {
              _term: "desc",
            },
            size: 10000,
          },
          aggs: {
            deviceId: {
              terms: {
                size: 10000,

                field: "deviceInfo.deviceUniqueIdentifier.keyword",
              },
            },
            userId: {
              terms: {
                size: 10000,

                field: "currentUserInfo.i.keyword",
              },
            },
            activeScene: {
              terms: {
                size: 10000,

                field: "activeScene.keyword",
              },
            },
            version: {
              terms: {
                size: 10000,

                field: "deviceInfo.gameVersion.keyword",
              },
            },
            os: {
              terms: {
                size: 10000,

                field: "deviceInfo.os.keyword",
              },
            },
            createdDate: {
              top_hits: {
                sort: [
                  {
                    "createdDate.keyword": {
                      order: "desc",
                    },
                  },
                ],
                size: 1,
                _source: {
                  includes: ["createdDate"],
                },
              },
            },
          },
        },
      },
      sort: [
        {
          "createdDate.keyword": {
            order: "desc",
          },
        },
      ],
      size: 0,
    };

    if (params.search) {
      query.query.bool.must.push({
        match_phrase_prefix: {
          condition: params.search,
        },
      });
    }
    if (params.version) {
      query.query.bool.must.push({
        term: {
          "deviceInfo.gameVersion": {
            value: params.version.version,
          },
        },
      });
    }
    if (state.timeSelect.id > -1) {
      let dt = new Date();
      dt.setMinutes(dt.getMinutes() - state.timeSelect.id);
      let date = Math.round(dt.getTime() / 1000);
      query.query.bool.must.push({
        range: {
          createdDate: {
            gte: date,
          },
        },
      });
    }
    let d = (
      await Api.post(`https://search-api.restplay.net/search/log_unity*`, query)
    ).data;

    context.commit("getErrors", d);
  },
  getErrorDetail: async (context, params) => {
    let query = {
      size: 50,
      sort: [
        {
          "createdDate.keyword": {
            order: "desc",
          },
        },
      ],
      query: {
        term: {
          "condition.keyword": {
            value: params,
          },
        },
      },
    };
    let d = (
      await Api.post(`https://search-api.restplay.net/search/log_unity*`, query)
    ).data;
    context.commit("getErrorDetail", d);
  },
  getVersion: async (context) => {
    let query = {
      size: 0,
      sort: [
        {
          "deviceInfo.gameVersion.keyword": {
            order: "desc",
          },
        },
      ],
      aggs: {
        version: {
          terms: {
            size: 100000,
            field: "deviceInfo.gameVersion.keyword",
          },
        },
      },
    };
    let d = (
      await Api.post(`https://search-api.restplay.net/search/log_unity*`, query)
    ).data;
    context.commit("getVersion", d);
  },
};
const mutations = {
  getVersion(state, error) {
    state.versions = error.aggregations.version.buckets.map((obj) => {
      return {
        version: obj.key,
      };
    });
  },
  getErrors(state, error) {
    state.all = error.aggregations.condition.buckets.map((obj) => {
      return {
        message: obj.key,
        total: obj.doc_count,
        createdDate: obj.createdDate.hits.hits[0]._source.createdDate,
        userTotal: obj.userId.buckets.length,
        deviceTotal: obj.deviceId.buckets.length,
        activeScene: obj.activeScene,
        version: obj.version,
        os: obj.os,
      };
    });
  },
  getParameters() {
    const prefix = "players.";
    ["select", "playerSelect", "sitSelect", "platformSelect"].forEach(
      (element) => {
        var elem = localStorage.getItem(prefix + element);
        if (elem) state[element] = JSON.parse(elem);
      }
    );
  },
  getErrorDetail(state, player) {
    state.error = player.hits.hits.map((o) => {
      return o._source;
    });
  },
  getPlayerOut(state, player) {
    // eslint-disable-next-line no-unused-vars
    player = player.aggregations.group_by_user.buckets.map((obj) => {
      return obj.top_user_hits.hits.hits[0]._source;
    });
    player.map((a) => {
      if (a.picture && a.picture.startsWith("/"))
        a.picture = "https://pokerface.restplay.com" + a.picture;
      else if (a.picture && !a.picture.startsWith("http"))
        a.picture = "https://pokerface.restplay.com/Avatars" + a.picture;

      if (a.bonusRewards)
        a.bonusRewardsCount = a.bonusRewards.reduce((a, b) => a + b.chip, 0);
    });
    state.playersOut = player;
  },
  getPlayerOutByUserId(state, player) {
    player = player.hits.hits.map((obj) => {
      return obj._source;
    });
    player.map((a) => {
      if (a.picture && a.picture.startsWith("/"))
        a.picture = "https://pokerface.restplay.com" + a.picture;
      else if (a.picture && !a.picture.startsWith("http"))
        a.picture = "https://pokerface.restplay.com/Avatars/" + a.picture;
      if (a.bonusRewards)
        a.bonusRewardsCount = a.bonusRewards.reduce((a, b) => a + b.chip, 0);
    });
    state.playersOutDetail = player;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
