import Api from "../baseApi";

const state = {
  all: [],
};
const getters = {
  setting: (state) => {
    return state.setting;
  },
};
const actions = {
  getAll: async (context, params) => {
    let url = `/management/CashPays?q=${params.search}&p=${params.page}&t=100`;
    if (params.searchTypeSelect.id != null) {
      url += `&mode=${params.searchTypeSelect.id}`;
    }
    if (params.options.sortBy[0])
      url += `&sortBy=${params.options.sortBy[0]}&sortDesc=${params.options.sortDesc[0]}`;

    let d = (await Api.get(url)).data;
    context.commit("getAll", d);
    return d;
  },
};
const mutations = {
  getAll(state, data) {
    state.all = data;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
