import Api from "../baseApi";

const state = {
  all: [],
  productSideProduct: [],
  detail: {},

};
const getters = {
  setting: (state) => {
    return state.setting;
  },
};
const actions = {
  getAll: async (context) => {
    let url = `/management/salesTargets`;
    let d = (await Api.get(url)).data;
    context.commit("getAll", d);
  },
  createOrUpdate: async (context, data) => {
    let url = `/management/salesTargets`;
    if (data.id)
      (await Api.put(url, data)).data;
    else
      (await Api.post(url, data)).data;
  },
  delete: async (context, id) => {
    Api.delete(`/management/salesTargets/${id}`);
  },
  get: async (context, id) => {
    let url = `/management/salesTargets/${id}`;
    let data = (await Api.get(url)).data;
    context.commit("get", data);
  },
};
const mutations = {
  getAll(state, settings) {
    state.all = settings;
  },
  get(state, data) {

    state.detail = data;
  },


};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
