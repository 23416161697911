import Api from "../baseApi";

const state = {
  detail: null,
  user: null,
  userMain: null,
  fields: [],
};

const actions = {
  getAll: async (context, params) => {
    let url = `/management/users/Query?p=${params.page}&t=100&status=${params.status}`;
    if (params.fieldsSelect != "-1" || params.field)
      url += `&field=${params.field || params.fieldsSelect}&value=${
        params.value
      }`;
    if (params.options.sortBy[0])
      url += `&sortBy=${params.options.sortBy[0]}&sortDesc=${params.options.sortDesc[0]}`;

    let d = (await Api.get(url)).data;
    context.commit("getAll", d);
    return d;
  },
  postAll: async (context, params) => {
    let url = `/management/users/Query?p=${params.page}&t=${params.take}&status=${params.status}`;
    if (params.options.sortBy[0])
      url += `&sortBy=${params.options.sortBy[0]}&sortDesc=${params.options.sortDesc[0]}`;

    let d = (await Api.post(url, params.body)).data;
    context.commit("getAll", d);
    return d;
  },
  getUserFields: async (context) => {
    let url = `/management/users/Fields`;

    let d = (await Api.get(url)).data;
    context.commit("getUserFields", d);
    return d;
  },
  getById: async (context, id) => {
    let d = (await Api.get(`/user/users/${id}`)).data;
    context.commit("getById", d);
  },
  getMainById: async (context, id) => {
    let d = (await Api.get(`/user/users/main/${id}`)).data;
    context.commit("getMainById", d);
  },
  kickUser: async (context, data) => {
    await Api.get(`/management/users/KickUser/${data.userId}/${data.time}`);
  },
};
const mutations = {
  getUserFields(state, settings) {
    state.fields = settings;
  },
  getAll(state, settings) {
    state.all = settings;
  },
  getById(state, data) {
    state.user = data == "" ? null : data;
  },
  getMainById(state, data) {
    state.userMain = data == "" ? null : data;
  },
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
