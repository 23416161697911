import Api from "./baseApi";
const state = {
  response: {},
  productTypeSelect: { id: 11, value: "Offer 1" },
  notificationBadge: {},
  ids: [],
  days: [
    { id: 0, value: "Pazar" },
    { id: 1, value: "Pazartesi" },
    { id: 2, value: "Salı" },
    { id: 3, value: "Çarşamba" },
    { id: 4, value: "Perşembe" },
    { id: 5, value: "Cuma" },
    { id: 6, value: "Cumartesi" },
  ],
  notificationType: [
    { id: 0, value: "Normal" },
    { id: 4, value: "ClaimBonus" },
  ],
};
const actions = {
  getAll: async (context) => {
    let url = `/management/dynamicOffers/`;
    let d = (await Api.get(url)).data;
    context.commit("getAll", d);
  },
  createOrUpdate: async (context, data) => {
    let url = `/management/dynamicOffers`;
    (await Api.post(url, data)).data;
  },
  transferProd: async (context, data) => {
    let url = `https://poker.restplay.com/management/dynamicOffers/TransferData`;
    (await Api.post(url, data)).data;
  },
  fileUpload: async (context, data) => {
    let url = `/management/dynamicOffers/fileUpload`;
    let d = (
      await Api.post(url, data.data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    ).data;
    return d;
  },
  send: async (context, id) => {
    let url = `/management/dynamicOffers/${id}`;
    (
      await Api.put(url, null, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    ).data;
  },
  delete: async (context, id) => {
    await Api.delete(`/management/dynamicOffers/${id}`);
  },
  get: async (context, id) => {
    let url = `/management/dynamicOffers/${id}`;
    let data = (await Api.get(url)).data;
    context.commit("get", data);
  },
  getIds: async (context) => {
    let url = `/management/dynamicOffers/ids`;
    let d = (await Api.get(url)).data;
    context.commit("getIds", d);
  },
  checkNotificationCount: async (context) => {
    let url = `/management/dynamicOffers`;
    let d = (await Api.get(url)).data;
    context.commit("checkNotificationCount", d);
  },
  sendNotification: async (context, data) => {
    if (!data.registrationToken) return;
    var message = {
      registration_ids: data.registrationToken,
      notification: {
        title: data.title,
        sound: "default",
        body: data.body,
        image: data.image,
      },
      content_available: true,
      priority: "high",
      data: {
        ...data.data,
      },
      iosBadgeNumber: 1,
      android: {
        // ttl: 3600 * 1000,
        notification: {
          icon: "stock_ticker_update",
          color: "#f45342",
        },
      },
      apns: {
        payload: {
          aps: {
            badge: 0,
          },
        },
      },
    };

    var response = (
      await Api.post(`/management/dynamicOffers/SendNotification`, message)
    ).data;
    // eslint-disable-next-line no-console
    console.log("Successfully sent message:", response);
    context.commit("sendNotification", response);
  },
};
const mutations = {
  getAll(state, settings) {
    state.all = settings;
  },
  get(state, data) {
    state.detail = data;
  },
  sendNotification(state, settings) {
    state.setting = settings;
  },

  getIds(state, data) {
    state.ids = data;
  },

  checkNotificationCount(state, badge) {
    state.notificationBadge = badge;
  },
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
